<template lang="pug">
.badge.d-flex.align-items-center.justify-content-center(
  :class="classes"
  @click="click"
  :style="badgeStyle"
)
  span {{ text }}
</template>
<script>
  const bgOptions = ['purple-gradient', 'purple'];
  const typeOptions = ['rounded', 'default'];

  export default {
    props: {
      color: {
        default: 'var(--brand-secondary-color)',
        type: String,
      },
      text: {
        type: String,
      },
      type: {
        type: String,
        options: typeOptions,
        default: 'default',
        validator(value) {
          return typeOptions.includes(value);
        },
      },
      bg: {
        options: bgOptions,
        default: 'purple-gradient',
        type: String,
        validator(value) {
          return bgOptions.includes(value);
        },
      },
      activeClass: {
        default: () => [],
        type: Array,
      },
    },
    data: () => ({
      bgOptions,
    }),
    computed: {
      classes() {
        const isBgClass = this.bgOptions.includes(this.bg);

        return {
          'badge-rounded': this.type === 'rounded',
          [`bg-${this.bg}`]: isBgClass,
          ...this.badgeActiveClasses,
        };
      },
      badgeActiveClasses() {
        return this.activeClass.reduce((prev, acc) => {
          prev = { ...prev, [acc]: true };

          return prev;
        }, {});
      },
      badgeStyle() {
        return {
          color: `${this.color}`,
        };
      },
    },
    methods: {
      click() {
        this.$emit('click');
      },
    },
  };
</script>

<style lang="sass" scoped>
  .badge
    border-radius: 3px
    min-width: 52px
    min-height: 20px
    padding: .125rem .375rem
    cursor: default
    &-rounded
      border-radius: 40px
  .bg
    &-purple-gradient
      background: linear-gradient(180deg, #8444E1 0.01%, #ED5A29 100%)
    &-purple
      background: linear-gradient(100.29deg, #8444E1 47.3%, #AE4D97 100.03%)
</style>
